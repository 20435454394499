<!--
 * @Author: your name
 * @Date: 2021-12-15 14:13:25
 * @LastEditTime: 2022-01-26 14:47:55
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\gk101\page1.vue
-->
<template>
  <div class="gk101-page1 pt80">
    <div class="title-div">
      <d-title model="ST-GK101" ch="智能高空虫情测报仪"></d-title>
    </div>
    <div class="img-div abs-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/gk101/1.png"
        alt="上太科技"
        class="data-img"
      />
    </div>
    <div class="filter-div abs-div"></div>
    <div class="abs-div" style="z-index: 3">
      <div class="text-div">
        <div class="t-top">
          <span>在线精准测报</span>
          <span>轻松搞定迁飞害虫</span>
        </div>
        <div class="b-div p-24">
          <span>针对草地贪夜蛾等迁飞性害虫防控，量身定做的专业级设备。</span>
          <span>采用高空投射灯，光控、时控、雨控、远程智能控制；</span>
          <span>自主杀虫，残骸称重，自动清倒一体化；</span>
          <span
            >玻璃球罩不易积虫，虫体不堆叠，维护更方便；球罩耐高温，雨天不炸裂，更加安全；</span
          >
          <span>多种端口控制，足不出户就能了解一切。</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dTitle from "@/components/sTitle/title";
export default {
  name: "",
  components: {
    dTitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.gk101-page1 {
  height: 100vh;
  overflow: hidden;
  width: 100%;
  .abs-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .title-div {
    position: relative;
    z-index: 3;
  }
  .img-div {
    z-index: 1;
    background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/gk101/1-1.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    transform: translateX(-6px);
    .data-img {
      transform: translateY(8%);
      width: 72%;
      display: block;
    }
  }
  .filter-div {
    z-index: 2;
    background-color: rgba($color: #000, $alpha: 0.8);
    opacity: 0;
  }
  .text-div {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 2;
    .t-top {
      font-size: 90px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: $color-active;
      opacity: 0;
      transform: translateY(50%);
      span {
        display: block;
      }
    }
    .b-div {
      opacity: 0;
      margin-top: 60px;
      transform: translateY(-20%);
      span {
        display: block;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .gk101-page1 {
    height: auto;
    overflow: hidden;
    width: 100%;
    .title-div {
      position: relative;
      z-index: 3;
      margin-top: 30px;
    }
    .img-div {
      position: relative !important;
      background-image: unset;
      .data-img {
        transform: translateY(0);
        margin-top: 16%;
        width: 150%;
        display: block;
      }
    }
  }
}
</style>
