<!--
 * @Author: your name
 * @Date: 2021-12-15 16:06:29
 * @LastEditTime: 2022-01-26 16:36:01
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\gk101\page4.vue
-->
<template>
  <div class="gk101-page4 pt80">
    <div class="imgs-div">
      <img
        class="f-img data-img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/gk101/animate/10000.png"
        alt="上太科技"
        @load="imgLoad"
      />
      <img
        class="data-img"
        v-for="(url, index) in imgArr"
        :key="index"
        :src="url"
        alt="上太科技"
      />
      <div class="test-div test-1">
        <img
          src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/gk101/4.png"
          class="c-img"
          alt="上太科技"
          @load="img1Load"
        />

        <div class="i-desc desc-t desc-1">
          <span class="line-span"></span>
          <p class="line-label">测报仓</p>
        </div>

        <div class="i-desc desc-t desc-2">
          <span class="line-span"></span>
          <p class="line-label">杀虫仓</p>
        </div>
      </div>
      <div class="test-div test-2">
        <img
          class="d-img"
          src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/gk101/5.png"
          alt="上太科技"
        />

        <div class="i-desc desc-3">
          <span class="line-span"></span>
          <p class="line-label">高清微电脑</p>
        </div>
      </div>
    </div>
    <div class="t-div t1-div">
      <ctitle titleb="智能杀虫、清虫模式"></ctitle>
      <p class="d-p">多仓并存，远程红外智能控温，烘干灭虫轻松完成。</p>
    </div>
    <div class="t-div t2-div">
      <ctitle titleb="智能触控 12.1寸高清微电脑"></ctitle>
      <p class="d-p">内置全中文高清微电脑</p>
    </div>
    <div class="content-div c1-div">
      <ul>
        <li v-for="item in arr" :key="item.title">
          <span class="t-span">{{ item.title }}</span>
          <span class="d-span">{{ item.desc }}</span>
        </li>
      </ul>
      <div class="d-div">
        <span> 注：以上信息中，监测数据为特定试验环境下抽样监测结果， </span>
        <span> 设备使用环境、使用方式及保养方式等对试验效果会有影响。 </span>
      </div>
    </div>
    <div class="content-div c2-div">
      <ul>
        <li v-for="item in arr2" :key="item.title">
          <span class="t-span">{{ item.title }}</span>
          <span class="d-span">{{ item.desc }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {
      imgArr: [],
      arr: [
        {
          title: "15 min",
          desc: "到达杀虫温度（处理时间可调）",
        },
        {
          title: "85±5 ℃",
          desc: "灭虫温度",
        },
        {
          title: "≥98 %",
          desc: "虫体处理致死率",
        },
        {
          title: "≥95 %",
          desc: "虫体完整率",
        },
      ],
      arr2: [
        {
          title: "12.1寸",
          desc: "高清液晶全面屏",
        },
        {
          title: "全屏触控",
          desc: "便捷化设置各类参数",
        },
        {
          title: "模式切换",
          desc: "分时段精准控制、自动转仓和手动转仓任意切换",
        },
      ],
    };
  },
  created() {
    this.loadImgs();
  },
  mounted() {
    window.addEventListener("resize", this.loadImgs, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.loadImgs, false);
  },
  methods: {
    /**
     * @description: 第一张图片加载成功
     */
    imgLoad() {
      let img = document.querySelector(".gk101-page5 .imgs-div .f-img");
    },
    /**
     * @description: 杀虫测报仓图片加载完毕
     */
    img1Load() {
      let img = document.querySelector(".gk101-page5 .test-div .c-img");
    },
    /**
     * @description: 加载图片
     */
    loadImgs() {
      if (window.innerWidth > 800) {
        for (let i = 1; i < 51; i++) {
          let name = (i < 10 ? "1000" : "100") + i;
          this.imgArr.push(
            `https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/gk101/animate/${name}.png`
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gk101-page4 {
  height: 100vh;
  position: relative;
  .imgs-div {
    position: absolute;
    width: 100%;
    height: 80%;
    top: 20%;
    left: 0;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      &:first-child {
        opacity: 1;
      }
    }
    .test-div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      &.test-1 {
        width: 11%;
        left: 49.8%;
      }
      &.test-2 {
        width: 7.6%;
        top: 60%;
        left: 51.5%;
      }
      img {
        width: 100%;
        display: block;
        object-fit: contain;
        opacity: 0;
      }
    }
  }
  .t-div {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.t2-div {
      opacity: 0;
    }
    .d-p {
      margin: 0;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.6);
      line-height: 40px;
      margin-top: 20px;
    }
  }
  .content-div {
    position: absolute;
    top: 20%;
    height: 80%;
    opacity: 0;
    &.c1-div {
      left: 11%;
    }
    &.c2-div {
      right: 11%;
      width: 300px;
    }
    ul {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-bottom: 130px;
      justify-content: space-between;
      li {
        display: flex;
        flex-direction: column;
        .t-span {
          font-size: 60px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #1dbf73;
        }
        .d-span {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          margin-top: 12px;
          color: rgba($color: #fff, $alpha: 0.6);
          line-height: 30px;
        }
      }
    }
    .d-div {
      position: absolute;
      bottom: 60px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.2);
    }
  }
}

.i-desc {
  position: absolute;
  top: 50%;
  overflow: hidden;
  width: 0;
  &.desc-1 {
    top: 60%;
    right: 88%;
  }
  &.desc-2 {
    top: 80%;
    right: 40%;
  }
  &.desc-3 {
    left: 66%;
    .line-label {
      text-align: right;
    }
  }
  .line-span {
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    width: 200px;
    height: 1px;
    background-color: rgba($color: #fff, $alpha: 0.5);
  }
  p {
    margin: 0;
    white-space: nowrap;
    color: $color-active;
    position: relative;
    height: 0;
    // height: 16px;
    margin-top: 10px;
    overflow: hidden;
    font-size: 22px;
    font-weight: bold;
    &.c-g {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.6;
    }
  }
}
</style>
