<!--
 * @Author: your name
 * @Date: 2021-12-15 16:40:02
 * @LastEditTime: 2021-12-27 10:09:14
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\gk101\page6.vue
-->
<template>
  <div class="gk101-page6">
    <div class="title-div">
      <span class="b-span">GPS地图定位</span>
      <span class="p-24"
        >通过平台可以远程设定工作时间和控制开关灯，每台设备都能通过GPS定位，在地图上面展现分布的点位，方便用户查看。</span
      >
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/gk101/7.jpg"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.gk101-page6 {
  height: 100vh;
  width: 100%;
  background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/gk101/7.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 100px;
  .title-div {
    text-align: center;
    span {
      display: block;
    }
    .b-span {
      font-size: 60px;
      font-family: Microsoft YaHei;
      color: #ffffff;
      line-height: 80px;
      font-weight: 600;
    }
    .p-24 {
      width: 800px;
      margin: auto;
      margin-top: 20px;
    }
  }
  .img-div {
    display: none;
  }
}

@media screen and (max-width: $mobile-width) {
  .gk101-page6 {
    height: auto;
    background-image: unset;
    padding-top: $mobile-header-height + 20px;
    overflow: hidden;
    .img-div {
      display: flex;
      justify-content: center;
      width: 100%;
      img {
        width: 140%;
        object-fit: cover;
      }
    }
    .title-div {
      text-align: center;
      span {
        display: block;
      }
      .b-span {
        font-size: 0.55rem;
        line-height: 1.09rem;
      }
      .p-24 {
        width: 86%;
        margin: auto;
        margin-top: 20px;
      }
    }
  }
}
</style>
