<!--
 * @Author: your name
 * @Date: 2021-12-15 16:28:12
 * @LastEditTime: 2022-01-26 14:53:18
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\gk101\page5.vue
-->
<template>
  <div class="gk101-page5">
    <div class="title-div">
      <span class="b-span">省心作业三部曲</span>
      <span class="c-label">
        <span>自主杀虫，残骸称重，自动清倒</span>
        <!-- <span class="f-span"></span>
        <span></span>
        <span class="f-span"></span>
        <span></span> -->
      </span>
      <span class="p-24"
        >虫量巨大时完全自主利用负压风机吸积害虫；独有残骸称重功能，搭载红外矩阵助力虫体计数，提供更多数据更多维度精准分析；在满仓后自动清倒，自动快速恢复杀虫功能。</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.gk101-page5 {
  height: 100vh;
  width: 100%;
  background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/gk101/6.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 100px;
  .title-div {
    text-align: center;
    span {
      display: block;
    }
    .b-span {
      font-size: 60px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #ffffff;
      line-height: 80px;
    }
    .c-label {
      font-size: 60px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: $color-active;
      line-height: 80px;
    }
    .p-24 {
      width: 992px;
      margin: auto;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .gk101-page5 {
    padding-top: $mobile-header-height + 20px;
    .title-div {
      span {
        display: block;
      }
      .f-span {
        display: none;
      }
      .b-span {
        font-size: 0.55rem;
        line-height: 1.09rem;
      }
      .c-label {
        font-size: 0.55rem;
        line-height: 0.73rem;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .p-24 {
        width: 86%;
        margin: auto;
        margin-top: 20px;
      }
    }
  }
}
</style>
