<!--
 * @Author: your name
 * @Date: 2021-12-15 15:52:12
 * @LastEditTime: 2021-12-27 09:22:39
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\gk101\pag3e.vue
-->
<template>
  <div class="gk101-page3">
    <div class="img-div"></div>
    <div class="filter-div abs-div"></div>
    <div class="text-div abs-div">
      <span class="title-s">场景应用广泛又省心</span>
      <p class="p-24">
        泛用于农业、林业及园艺、畜牧等。可安装在楼顶、高台等相对开阔处，或安装在病虫观测场内，集中诱杀迁飞性害虫。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.gk101-page3 {
  height: 100vh;
  overflow: hidden;
  .abs-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .img-div {
    width: 100%;
    height: 100%;
    background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/gk101/3.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .filter-div {
    z-index: 2;
    background-color: rgba($color: #000, $alpha: 0.8);
  }
  .text-div {
    z-index: 3;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    .title-s {
      font-size: 85px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 140px;
    }
    .p-24 {
      width: 752px;
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .gk101-page3 {
    .text-div {
      justify-content: center;
      .title-s {
        font-size: 0.55rem;
        line-height: 1.09rem;
      }
      .p-24 {
        width: 86%;
      }
    }
  }
}
</style>
