<!--
 * @Author: your name
 * @Date: 2021-12-15 15:21:11
 * @LastEditTime: 2022-01-26 14:50:19
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\gk101\page2.vue
-->
<template>
  <div class="gk101-page2">
    <div class="img-div abs-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/gk101/2.png"
        alt="上太科技"
        class="data-img"
      />
    </div>
    <div class="filter-div abs-div"></div>
    <div class="text-div abs-div">
      <div class="t-top">
        <span class="label-s">诱集迁飞性害虫</span>
        <span class="p-24 desc-s"
          >专为迁飞害虫研制，使用方便，诱剂数量多，提高精准防控能力</span
        >
      </div>
      <ul class="msg-ul">
        <li>玻璃球罩高空探射灯</li>
        <li>500-2000M照射高度</li>
        <li>不积虫/不炸裂</li>
        <!-- <li class="p-24">光源可选配</li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.gk101-page2 {
  position: relative;
  height: 100vh;
  overflow: hidden;
  .abs-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .img-div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .data-img {
      height: calc(100% - 200px);
      object-fit: contain;
      position: absolute;
      bottom: -3%;
      transform: scale(2);
    }
  }
  .filter-div {
    z-index: 2;
    background-color: rgba($color: #000, $alpha: 0.8);
  }
  .text-div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 50px;
    z-index: 3;
    .t-top {
      transform: translateY(100%);
      opacity: 0;
      .desc-s {
        opacity: 0;
      }
      span {
        display: block;
      }
    }
    .label-s {
      font-size: 90px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: $color-active;
      margin-bottom: 10px;
    }
    .msg-ul {
      margin-top: 30px;
      transform: translateY(-20%);
      opacity: 0;
      font-size: 60px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 80px;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .gk101-page2 {
    position: relative;
    height: auto;
    overflow: hidden;
    .abs-div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .img-div {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .data-img {
        height: calc(100% - 200px);
        object-fit: contain;
        position: absolute;
        bottom: -3%;
        transform: scale(2);
      }
    }
    .filter-div {
      z-index: 2;
      background-color: rgba($color: #000, $alpha: 0.8);
    }
    .text-div {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-bottom: 50px;
      z-index: 3;
      .t-top {
        transform: translateY(100%);
        opacity: 0;
        .desc-s {
          opacity: 0;
        }
        span {
          display: block;
        }
      }
      .label-s {
        font-size: 90px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: $color-active;
        margin-bottom: 10px;
      }
      .msg-ul {
        margin-top: 70px;
        transform: translateY(-20%);
        opacity: 0;
        font-size: 60px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 80px;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .gk101-page2 {
    height: auto;
    .img-div.abs-div {
      position: relative;
      .data-img {
        position: relative;
        width: 160%;
        height: auto;
        bottom: 0;
        transform: scale(1);
      }
    }
    .text-div {
      padding-top: 40px;
      .t-top {
        opacity: 1;
        transform: translateY(0%);
        .label-s {
          font-size: 0.73rem;
        }
        .desc-s {
          opacity: 1;
        }
      }
      .msg-ul {
        opacity: 1;
        font-size: 0.55rem;
        line-height: 1.09rem;
        transform: translateY(0);
      }
    }
  }
}
</style>
