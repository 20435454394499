<!--
 * @Author: your name
 * @Date: 2021-12-27 08:51:32
 * @LastEditTime: 2021-12-27 17:40:14
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\gk101\page1Mobile.vue
-->
<template>
  <div class="gk101-page1-mobile pt80">
    <div>
      <div class="title-div">
        <d-title model="ST-GK101" ch="智能高空虫情测报仪"></d-title>
      </div>
      <div class="img-div abs-div">
        <img
          src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/gk101/1.png"
          alt="上太科技"
          class="data-img"
        />
      </div>
    </div>
    <div class="p1-2">
      <div class="img-div">
        <img
          src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/gk101/1.png"
          alt="上太科技"
          class="data-img"
        />
      </div>

      <div class="filter-div abs-div"></div>
      <div class="abs-div" style="z-index: 3">
        <div class="text-div">
          <div class="t-top">
            <span>在线精准测报</span>
            <span>轻松搞定迁飞害虫</span>
          </div>
          <div class="b-div p-24">
            <span>针对草地贪夜蛾等迁飞性害虫防控，量身定做的专业级设备。</span>
            <span>采用高空投射灯，光控、时控、雨控、远程智能控制；</span>
            <span>自主杀虫，残骸称重，自动清倒一体化；</span>
            <span
              >玻璃球罩不易积虫，虫体不堆叠，维护更方便；球罩耐高温，雨天不炸裂，更加安全；</span
            >
            <span>多种端口控制，足不出户就能了解一切。</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dTitle from "@/components/sTitle/title";
export default {
  name: "",
  components: {
    dTitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.gk101-page1-mobile {
  width: 100%;
  overflow: hidden;
  .title-div {
    margin-top: 30px;
  }
  .img-div {
    display: flex;
    justify-content: center;
    width: 86%;
    margin: auto;
    margin-top: 40px;
    img {
      width: 180%;
      object-fit: contain;
    }
  }
  .p1-2 {
    position: relative;
    .abs-div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .filter-div {
      z-index: 2;
      background-color: rgba($color: #000, $alpha: 0.8);
      opacity: 1;
    }
    .text-div {
      width: 86%;
      margin: auto;
      padding: 0.45rem 0;
      padding-top: 0.71rem;
      .t-top {
        font-size: 0.73rem;
        font-family: PingFang SC;
        color: $color-active;
        text-align: center;
        span {
          display: block;
        }
      }
      .b-div {
        margin-top: 0.36rem;
        text-align: center;
        span {
          display: block;
        }
      }
    }
  }
}
</style>
