<!--
 * @Author: your name
 * @Date: 2021-12-15 14:09:14
 * @LastEditTime: 2022-01-26 16:38:36
 * @LastEditors: Please set LastEditors
 * @Description: 高空测报
 * @FilePath: \new-website-1123\src\views\special\gk101\index.vue
-->
<template>
  <div class="gk101-page">
    <div class="sticky-box1">
      <div class="sticky-child">
        <page1></page1>
      </div>
    </div>
    <page1-mobile class="page1-mobile"></page1-mobile>
    <div class="sticky-box2">
      <div class="sticky-child">
        <page2></page2>
      </div>
    </div>
    <div class="sticky-box3">
      <div class="sticky-child">
        <page3></page3>
      </div>
    </div>
    <div class="sticky-box4">
      <div class="sticky-child">
        <page4></page4>
      </div>
    </div>
    <page4-mobile class="page4-mobile"></page4-mobile>
    <page5 class="opacity-page scale-page"></page5>
    <page6 class="opacity-page scale-page"></page6>
  </div>
</template>

<script>
import page1 from "./page1.vue";
import page1Mobile from "./page1Mobile.vue";
import page2 from "./page2.vue";
import page3 from "./page3.vue";
import page4 from "./page4.vue";
import page4Mobile from "./page4Mobile.vue";
import page5 from "./page5.vue";
import page6 from "./page6.vue";
import {getNesViewApi} from '@/api/index.js'
export default {
  name: "gk101-page",
  components: {
    page1,
    page1Mobile,
    page2,
    page3,
    page4,
    page4Mobile,
    page5,
    page6,
  },
  data() {
    return {
      scrollTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.cScroll, false);
    this.addView()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.cScroll, false);
  },
  methods: {
    addView(){
      getNesViewApi({type:1,pid:"1c317ff1e1c84c9997e817df289feb8a"})
    },
    /**
     * @description: 监听滚动事件
     */
    cScroll() {
      let html = document.querySelector("html");
      this.scrollTop = html.scrollTop;
      if (window.innerWidth > 800) {
        this.page1s();
        this.page2s();
        this.page3s();
        this.page5s();
      }
    },

    /**
     * @description: page5 的滚动监听
     */
    page5s() {
      let box5 = document.querySelector(".sticky-box4");
      let div = document.querySelector(".gk101-page4");
      let imgDiv = div.querySelector(".imgs-div");

      let t1 = div.querySelector(".t1-div");
      let t2 = div.querySelector(".t2-div");

      let cImg = imgDiv.querySelector(".c-img");
      let descT = imgDiv.querySelectorAll(".desc-t");
      let pT = imgDiv.querySelectorAll(".desc-t p");

      let dImg = imgDiv.querySelector(".d-img");
      let desc3 = imgDiv.querySelector(".desc-3");
      let p3 = imgDiv.querySelector(".desc-3 p");

      let c1Div = div.querySelector(".c1-div");
      let c2Div = div.querySelector(".c2-div");

      let img = imgDiv.querySelectorAll(`.data-img`);
      let cTop = box5.offsetTop - this.scrollTop;
      if (cTop < -5800) {
        let top = cTop + 5800;
        let rate = Math.abs(top / 500);
        c2Div.style.opacity = rate;
        p3.style.height = 30 + "px";
      } else if (cTop < -5500) {
        let top = cTop + 5500;
        let rate = Math.abs((top / 300) * 30);
        c2Div.style.opacity = 0;
        p3.style.height = rate + "px";
        desc3.style.width = 200 + "px";
      } else if (cTop < -5000) {
        let top = cTop + 5000;
        let rate = Math.abs(top / 500);
        dImg.style.opacity = 1;
        desc3.style.width = rate * 200 + "px";
        p3.style.height = 0;

        for (let i = 0; i < img.length; i++) {
          const element = img[i];
          element.style.opacity = "0";
        }
        img[50].style.opacity = "1";
      } else if (cTop < -4700) {
        let top = cTop + 4700;
        let rate = Math.abs(top / 300);
        dImg.style.opacity = rate;
        desc3.style.width = "0px";
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = 0;
        }
        for (let i = 0; i < img.length; i++) {
          const element = img[i];
          element.style.opacity = "0";
        }
        img[50].style.opacity = "1";

        t2.style.opacity = 1;
        t1.style.opacity = 0;
      } else if (cTop < -4100) {
        let top = cTop + 4100;
        let rate = Math.abs(top / 400);
        let name = Math.abs(parseInt((top / 600) * 51));
        for (let i = 0; i < img.length; i++) {
          const element = img[i];
          element.style.opacity = "0";
        }
        img[name].style.opacity = "1";

        t1.style.opacity = 1 - rate;
        t2.style.opacity = rate;

        cImg.style.opacity = 0;
        dImg.style.opacity = 0;
      } else if (cTop < -3900) {
        t1.style.opacity = 1;
        t2.style.opacity = 0;

        let top = cTop + 3900;
        let rate = Math.abs(top / 300);
        cImg.style.opacity = 1 - rate;
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = 0;
        }

        for (let i = 0; i < img.length; i++) {
          const element = img[i];
          element.style.opacity = "0";
        }
        img[0].style.opacity = "1";
      } else if (cTop < -3400) {
        let top = cTop + 3400;
        let rate = Math.abs(top / 500);
        rate = 1 - Math.min(rate, 1);
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = rate * 200 + "px";
        }
        for (let i = 0; i < pT.length; i++) {
          const element = pT[i];
          element.style.height = 0;
        }
        cImg.style.opacity = 1;
      } else if (cTop < -3100) {
        let top = cTop + 3100;
        let rate = Math.abs((top / 300) * 30);
        for (let i = 0; i < pT.length; i++) {
          const element = pT[i];
          element.style.height = 30 - rate + "px";
        }
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = 200 + "px";
        }
        c1Div.style.opacity = 0;
      } else if (cTop < -2600) {
        let top = cTop + 2600;
        let rate = Math.abs(top / 500);
        c1Div.style.opacity = 1 - rate;
        for (let i = 0; i < pT.length; i++) {
          const element = pT[i];
          element.style.height = 30 + "px";
        }
      } else if (cTop < -1600) {
        let top = cTop + 1600;
        let rate = Math.abs(top / 500);
        c1Div.style.opacity = rate;
        for (let i = 0; i < pT.length; i++) {
          const element = pT[i];
          element.style.height = 30 + "px";
        }
      } else if (cTop < -1300) {
        c1Div.style.opacity = 0;
        let top = cTop + 1300;
        let rate = Math.abs((top / 300) * 30);
        for (let i = 0; i < pT.length; i++) {
          const element = pT[i];
          element.style.height = rate + "px";
        }
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = 200 + "px";
        }
      } else if (cTop < -800) {
        let top = cTop + 800;
        let rate = Math.abs(top / 500);
        rate = Math.min(rate, 1);
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = rate * 200 + "px";
        }
        for (let i = 0; i < pT.length; i++) {
          const element = pT[i];
          element.style.height = 0;
        }
        cImg.style.opacity = 1;
      } else if (cTop < -300) {
        let top = cTop + 300;
        let rate = Math.abs(top / 300);
        cImg.style.opacity = rate;
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = 0;
        }
      } else if (cTop < 0) {
        cImg.style.opacity = 0;
      }
    },
    page3s() {
      let box = document.querySelector(".sticky-box3");
      let div = box.querySelector(".gk101-page3");
      let filterBox = div.querySelector(".filter-div");
      let text = div.querySelector(".text-div");
      let img = div.querySelector(".img-div");
      let ctop = this.scrollTop - box.offsetTop;
      if (ctop > 1000) {
        let rate = Math.min(1, (ctop - 1000) / 400);
        img.style.transform = `scale(${0.8 + (1 - rate) * 0.2})`;

        text.style.opacity = filterBox.style.opacity = 0;
      } else if (ctop > 400) {
        img.style.transform = "scale(1)";

        let rate = Math.min(1, (ctop - 400) / 400);
        text.style.opacity = filterBox.style.opacity = 1 - rate;
      } else if (ctop < 400) {
        text.style.opacity = filterBox.style.opacity = 1;
      }
    },
    page2s() {
      let box = document.querySelector(".sticky-box2");
      let div = box.querySelector(".gk101-page2");
      let filterBox = div.querySelector(".filter-div");
      let img = div.querySelector(".data-img");
      let textTop = div.querySelector(".t-top");
      let text = div.querySelector(".label-s");
      let desc = div.querySelector(".desc-s");
      let msg = div.querySelector(".msg-ul");
      let stop = this.scrollTop - box.offsetTop;
      if (stop > 1700) {
        textTop.style.transform = `translateY(0%)`;
        msg.style.transform = `translateY(0%)`;
        msg.style.opacity = desc.style.opacity = 1;
      } else if (stop > 1200) {
        let rate = Math.min(1, (stop - 1200) / 500);

        textTop.style.transform = `translateY(${(1 - rate) * 100}%)`;
        msg.style.transform = `translateY(-${(1 - rate) * 20}%)`;
        msg.style.opacity = desc.style.opacity = rate;

        text.style.transform = `scale(1)`;
        textTop.style.opacity = 1;
      } else if (stop > 700) {
        textTop.style.transform = `translateY(100%)`;
        msg.style.transform = `translateY(-20%)`;
        msg.style.opacity = desc.style.opacity = 0;

        let rate = Math.min(1, (stop - 700) / 500);
        let scale = Math.max(1, (1 - rate) * 4);
        text.style.transform = `scale(${scale})`;
        textTop.style.opacity = rate;

        filterBox.style.opacity = 1;
        img.style.transform = `scale(1)`;
      } else if (stop > 200) {
        textTop.style.opacity = 0;

        let rate = Math.min(1, (stop - 200) / 500);
        filterBox.style.opacity = rate;
        img.style.transform = `scale(${2 - rate})`;
      } else if (stop < 200) {
        filterBox.style.opacity = 0;
        img.style.transform = "scale(2)";
      }
    },
    page1s() {
      let div = document.querySelector(".gk101-page1");
      let title = div.querySelector(".title-div");
      let img = div.querySelector(".data-img");
      let filterBox = div.querySelector(".filter-div");
      let text = div.querySelector(".text-div");
      let textTop = div.querySelector(".t-top");
      let desc = div.querySelector(".b-div");
      if (this.scrollTop > 1700) {
        textTop.style.transform = `translateY(0%)`;
        desc.style.opacity = 1;
        desc.style.transform = `translateY(0%)`;

        text.style.transform = `scale(1)`;
        textTop.style.opacity = 1;
      } else if (this.scrollTop > 1300) {
        let rate = Math.min(1, (this.scrollTop - 1300) / 400);
        textTop.style.transform = `translateY(${(1 - rate) * 50}%)`;
        desc.style.opacity = rate;
        desc.style.transform = `translateY(-${(1 - rate) * 20}%)`;

        text.style.transform = `scale(1)`;
        textTop.style.opacity = 1;
      } else if (this.scrollTop > 900) {
        desc.style.opacity = 0;
        let rate = Math.min(1, (this.scrollTop - 900) / 400);
        let scale = Math.max(1, (1 - rate) * 4);
        text.style.transform = `scale(${scale})`;
        textTop.style.opacity = rate;
        filterBox.style.opacity = 1;
      } else if (this.scrollTop > 600) {
        textTop.style.opacity = 0;
        let rate = Math.min(1, (this.scrollTop - 600) / 300);
        title.style.opacity = 0;
        img.style.transform = `translateY(0)`;
        filterBox.style.opacity = rate;
      } else if (this.scrollTop > 200) {
        filterBox.style.opacity = 0;
        let rate = Math.min(1, (this.scrollTop - 200) / 400);
        title.style.opacity = 1 - rate;
        img.style.transform = `translateY(${(1 - rate) * 8}%)`;
      } else if (this.scrollTop < 200) {
        title.style.opacity = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky-child {
  position: sticky;
  top: 0;
  height: 100vh;
}
.sticky-box1 {
  height: calc(100vh + 2000px);
}

.page1-mobile {
  display: none;
}
.sticky-box2 {
  height: calc(100vh + 2000px);
}
.sticky-box3 {
  height: calc(100vh + 1500px);
}

.sticky-box4 {
  height: calc(100vh + 6400px);
}

.page4-mobile {
  display: none;
}

@media screen and (max-width: $mobile-width) {
  .sticky-child {
    position: relative;
    top: 0;
    height: auto;
  }
  .sticky-box1 {
    height: auto;
    display: none;
  }
  .sticky-box2 {
    height: auto;
  }
  .sticky-box3 {
    height: auto;
  }

  .sticky-box4 {
    height: auto;
    display: none;
  }
  .page4-mobile {
    display: block;
  }
  .page1-mobile {
    display: block;
  }
}
</style>
